.section-hero-2 {
    .background {
        .bg-w {
            display: block;
        }
        .bg-d {
            display: none;
        }
    }
    .icon-decorate {
        margin-right: 210px;
        padding-bottom: 30px;
    }
    .carouselTicker__item {
        margin: 0 10px;
        float: left;
        text-align: center;
    }
    .brand-logo {
        transition: all 0.3s ease;
        background-color: #f8f8f8;
        border: 1px solid var(--tc-border-1);
        &:hover {
            transition: all 0.3s ease;
            background-color: #242424;
            border: none;
        }
    }
}
.dot-decor {
    width: 5px;
    height: 5px;
    color: var(--tc-theme-primary-2);
    border-radius: 50%;
}
.bg-overlay {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    height: 80%;
    width: 100%;
}
.line-left {
    top: 40px;
    height: 90%;
    left: 5px;
}
.fill-primary-2 {
    fill: var(--tc-theme-primary-2);
}
.section-coporation {
    .circle-1 {
        width: 124px;
        height: 124px;
        border-radius: 50%;
        border: 1px solid var(--tc-border-1);
    }
    .circle-2 {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        border: 1px solid var(--tc-border-1);
    }

    .decorate {
        bottom: -35px;
        right: -35px;
        .circle-1-1 {
            width: 210px;
            height: 210px;
            border: 0.4px solid var(--tc-border-1);
            border-radius: 50%;
        }
        .circle-1-2 {
            width: 124px;
            height: 124px;
            border: 0.4px solid var(--tc-border-1);
            border-radius: 50%;
        }
        .circle-1-3 {
            width: 82px;
            height: 82px;
            border: 0.4px solid var(--tc-border-1);
            border-radius: 50%;
        }
    }
}
.section-skills-2 {
    .carouselTicker__wrap {
        overflow: visible !important;
    }
    .carouselTicker__item {
        position: relative;
        margin: 0 15px;
        float: left;
        text-align: center;
        .tool-tip {
            // visibility: hidden;
            opacity: 0;
            position: absolute;
            top: -40px;
            left: -10px;
            width: max-content;
            // display: inline-flex;
            padding: 2px 15px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: #fff;
            border-radius: 5px;
            background: #1f1f24;
            transition: all 0.3s ease;
            &::before {
                content: url(../imgs/home-page-2/template/down.svg);
                position: absolute;
                bottom: -14px;
                left: 30%;
            }
        }
        &:hover {
            .tool-tip {
                // visibility: visible;
                opacity: 1;
                transition: all 0.3s ease;
            }
        }
    }
    .brand-logo {
        transition: all 0.3s ease;
        background-color: #f8f8f8;
        border: 1px solid var(--tc-border-1);
        &:hover {
            transition: all 0.3s ease;
            background-color: #242424;
            border: none;
        }
    }
    .decorate {
        top: -35px;
        right: -35px;
        .circle-1-1 {
            width: 210px;
            height: 210px;
            border: 0.4px solid var(--tc-border-1);
            border-radius: 50%;
        }
        .circle-1-2 {
            width: 124px;
            height: 124px;
            border: 0.4px solid var(--tc-border-1);
            border-radius: 50%;
        }
        .circle-1-3 {
            width: 82px;
            height: 82px;
            border: 0.4px solid var(--tc-border-1);
            border-radius: 50%;
        }
    }
}

.section-service-2 {
    .card-servies-2 {
        padding: 93px 42px 42px 42px;
        background-color: var(--tc-bg-3);
        border: 1px solid var(--tc-border-1);
        svg {
            path {
                fill: var(--tc-neutral-0);
                transition: all 0.3s ease;
            }
        }
        &:hover {
            svg {
                path {
                    fill: var(--tc-theme-primary-2);
                    transition: all 0.3s ease;
                }
            }
        }
    }
}
.section-experience {
    .technology {
        &:hover {
            background-color: var(--tc-border-1) !important;
        }
    }
}
.section-projects-2 {
    .link-hover {
        transition: all 0.2s ease;
        svg {
            path {
                transition: all 0.2s ease;
                fill: var(--tc-neutral-300) !important;
            }
        }
        &:hover {
            color: var(--tc-theme-primary-2) !important;
            transition: all 0.2s ease;
            border-color: var(--tc-theme-primary-2) !important;
            svg {
                path {
                    transition: all 0.2s ease;
                    fill: var(--tc-theme-primary-2) !important;
                }
            }
        }
    }
}
.section-blog-2 {
    .blog-card {
        padding: 14px 14px 44px 14px;
        border: 2px solid transparent;
        background:
            linear-gradient(var(--tc-bg-3), var(--tc-bg-3)) padding-box,
            (var(--tc-linear-3)) border-box;
        .blog-card__image {
            .blog-card__link {
                background: var(--tc-theme-primary-2);
                opacity: 0;
                transition: all 0.2s ease-in-out;
                i {
                    color: #000;
                }
            }
            .text-white-keep {
                &:hover {
                    color: var(--tc-theme-primary-2) !important;
                    border-color: var(--tc-theme-primary-2) !important;
                }
            }
        }
        .blog-card__content {
            .blog-card__title {
                transition: all 0.2s ease-in-out;
            }
            &:hover {
                .blog-card__title {
                    color: var(--tc-theme-primary-2);
                }
            }
        }
        &:hover {
            // background:
            //     linear-gradient(var(--tc-bg-1), var(--tc-bg-1)) padding-box,
            //     (var(--tc-linear-2)) border-box;
            .blog-card__image {
                .blog-card__link {
                    opacity: 1;
                }
            }
        }
    }
}
